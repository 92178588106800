//import '@fullcalendar/core/vdom'
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { createPopper } from '@popperjs/core';

const calendarElement = document.getElementById('calendar');

if (calendarElement) {

    // Set the filters
    const filterFarm = document.querySelector<HTMLInputElement>('.calendar-farm');
    const filterPantry = document.querySelector<HTMLInputElement>('.calendar-pantry');

    // Set the initial view
    let initialView = 'listWeek';
    if (window.innerWidth > 992) {
        initialView = 'dayGridMonth';
    }

    // Create the calendar
    let calendar = new Calendar(calendarElement, {
        selectable: undefined,
        plugins: [ bootstrap5Plugin, listPlugin, dayGridPlugin ],
        themeSystem: 'bootstrap5',
        initialView: initialView,
        eventClick: (info) => {
            info.jsEvent.preventDefault();
        },
        eventMouseEnter: (arg) => {
            if (arg.event.extendedProps.description) {
                let tip = document.createElement('span');
                tip.id = arg.event.id;
                tip.classList.add('text-bg-primary');
                tip.innerText = arg.event.extendedProps.description;

                createPopper(arg.el, tip, {
                    placement: 'top',
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 10],
                            },
                        },
                    ]
                });

                arg.el.append(tip);
            }
        },
        eventMouseLeave: (arg) => {
            let tip = document.getElementById(arg.event.id);
            if (tip) {
                tip.remove();
            }
        },
        eventClassNames: (arg) => {
            /* Only Show selected calendar items */
            let classes = ['d-none'];

            if (arg.event.extendedProps.calendar.includes('farm') && filterFarm.checked) {
                console.log('farm filter: ' + filterFarm.checked);
                classes = [];
            }

            if (arg.event.extendedProps.calendar.includes('pantry') && filterPantry.checked) {
                console.log('pantry filter: ' + filterPantry.checked);
                classes = [];
            }
            return classes;
        },
        events: '/calendar/events'
    });

    calendar.render();

    // Calendar filter re-render
    filterFarm.addEventListener('change', () => {
        console.log('Calendar farm changed');
        calendar.render();
    });
    filterPantry.addEventListener('change', () => {
        console.log('Calendar pantry changed');
        calendar.render();
    })

    // On window resize change the calendar view
    window.addEventListener('resize', () => {
        if (window.innerWidth <= 992 && calendar.view.type === 'dayGridMonth') {
            calendar.changeView('listWeek');
        } else if (window.innerWidth > 992 && calendar.view.type === 'listWeek') {
            calendar.changeView('dayGridMonth');
        }
    })
}
